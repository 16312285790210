var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"MdlBnn banner"},[(_vm.generic.length > 0)?_c('ModuleHeader',{attrs:{"generic":_vm.generic}}):_vm._e(),_vm._v(" "),(_vm.$isAMP)?[(_vm.setLinkType(_vm.data.link.url) == 'EXTERNO')?[_c('a',{attrs:{"target":_vm.data.link.target === '_blank' ? '_blank' : false,"rel":_vm.data.link.nofollow !== '' ? 'nofollow' : false,"href":_vm.data.link.track !== ''
            ? _vm.setDynamicShopLink(
              'boton_banner',
              _vm.data.shop ? _vm.data.shop : 'vacia',
              _vm.data.link.track,
              '',
              'Modulo banner',
              '',
              false
            )
            : _vm.data.link.url}},[_c('amp-img',{staticClass:"w-100",attrs:{"layout":"responsive","src":("" + (_vm.data.photoMobile && _vm.data.photoMobile.src ? _vm.data.photoMobile.src : _vm.data.photo.src)),"width":_vm.data.photoMobile && _vm.data.photoMobile.width ? _vm.data.photoMobile.width : 500,"height":_vm.data.photoMobile && _vm.data.photoMobile.height ? _vm.data.photoMobile.height : 500,"alt":_vm.data.link.title}})],1)]:[_c('nuxt-link',{attrs:{"target":_vm.data.link.target === '_blank' ? '_blank' : false,"rel":_vm.data.link.nofollow !== '' ? 'nofollow' : false,"to":_vm.setNuxtLink(_vm.data.link.url)}},[_c('amp-img',{staticClass:"w-100",attrs:{"src":("" + (_vm.data.photoMobile && _vm.data.photoMobile.src ? _vm.data.photoMobile.src : _vm.data.photo)),"width":_vm.data.photoMobile && _vm.data.photoMobile.width ? _vm.data.photoMobile.width : 500,"height":_vm.data.photoMobile && _vm.data.photoMobile.height ? _vm.data.photoMobile.height : 500,"alt":_vm.data.link.title}})],1)]]:[(_vm.setLinkType(_vm.data.link.url) == 'EXTERNO')?[_c('a',{attrs:{"target":_vm.data.link.target === '_blank' ? '_blank' : false,"rel":_vm.data.link.nofollow !== '' ? 'nofollow' : false,"href":_vm.data.link.track !== ''
            ? _vm.setDynamicShopLink(
              'boton_banner',
              _vm.data.shop ? _vm.data.shop : 'vacia',
              _vm.data.link.track,
              '',
              'Modulo banner',
              '',
              false
            )
            : _vm.data.link.url}},[_c('b-img',{staticClass:"w-100",attrs:{"loading":"lazy","srcset":("," + (_vm.data.photo.src) + "1300w, " + (_vm.data.photoMobile && _vm.data.photoMobile.src ? _vm.data.photoMobile.src : _vm.data.photo.src) + " 767w"),"width":_vm.data.photo.width ? _vm.data.photo.width : 1300,"height":_vm.data.photo.height ? _vm.data.photo.height : 150,"alt":_vm.data.link.title}})],1)]:[_c('nuxt-link',{attrs:{"target":_vm.data.link.target === '_blank' ? '_blank' : false,"rel":_vm.data.link.nofollow !== '' ? 'nofollow' : false,"to":_vm.setNuxtLink(_vm.data.link.url)}},[_c('b-img',{staticClass:"w-100",attrs:{"loading":"lazy","srcset":("," + (_vm.data.photo.src) + "1300w, " + (_vm.data.photoMobile && _vm.data.photoMobile.src ? _vm.data.photoMobile.src : _vm.data.photo.src) + " 767w"),"width":_vm.data.photo.width ? _vm.data.photo.width : 1300,"height":_vm.data.photo.height ? _vm.data.photo.height : 150,"alt":_vm.data.link.title}})],1)]],_vm._v(" "),(_vm.generic.length > 0)?_c('ModuleFooter',{attrs:{"generic":_vm.generic}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }